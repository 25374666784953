/**
 *@Description 客服信息条
 *@author meng wang
 *@date 2020/10/27
 */
/*eslint-disable*/
import React, { useState } from 'react'
import { Dropdown, Icon, Image } from '../../common'
import { ControlModal } from '../modal'

export default function StickyBar({ user }) {
  const [ifTry, setIfTry] = useState(false)
  function wantTry() {
    setIfTry(true)
  }
  return (
    <>
      <div className="c-sbar">
        <a className="c-sbar__item pd" onClick={wantTry}>
          <Icon name="message-square" cl="c-sbar__fill-icon no-stroke" />
          <div className="c-sbar__label">免费咨询</div>
        </a>

        <Dropdown className="js-dropdown pd">
          <a className="c-sbar__item c-tooltip js-dropdown">
            <div className="js-dropdown__toggle">
              <Icon
                name="/images/icons/logo.svg"
                type="oc"
                // size="square-sm"
                cl="no-stroke"
              />
              <div className="c-sbar__label">公众号</div>
            </div>
            <div className="c-tooltip__popup js-dropdown__popup c-tooltip__popup--tr c-tooltip__popup-md--cr c-tooltip--animated c-sbar__tooltip">
              <div className="c-tooltip__popup__inner c-tooltip__popup__inner--light">
                <Image
                  alt=""
                  src="/images/pages/about/weixin.jpg"
                  width="180"
                  height="180"
                />
                <div className="u-font-size-xs u-mt-xs">扫码关注：荃程无忧</div>
              </div>
            </div>
          </a>
        </Dropdown>
        <a
          className="c-sbar__item"
          onClick={() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })}
        >
          <Icon name="chevron-up" />
          <div className="c-sbar__label">TOP</div>
        </a>
      </div>
      {ifTry && <ControlModal user={user} close={() => setIfTry(false)} />}
    </>
  )
}
