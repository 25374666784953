// /* eslint-disable no-plusplus */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Menu from 'antd/lib/menu'
import Icon from '../../common/icon'
import Dropdown from '../../common/dropdown'
import API from '../../../utils/api'

const { SubMenu } = Menu

export default function Header({ tutor = {}, menu }) {
  const [menuMobile, setMenu] = useState(false)
  const [bell, setBell] = useState(false)
  const [tab, setTab] = useState(0)
  const [count, setCount] = useState(tutor.unread_count || 0)
  const [notification, setNotification] = useState({
    questions: [],
    reviews: [],
  })
  function handleToggle(e) {
    e.preventDefault()
    setMenu(!menuMobile)
  }
  async function readAll() {
    if (tutor.unread_count) {
      const { error } = await API.get('tutor/easyke_mentors/read_all')
      if (!error) {
        setCount(0)
      }
    }
  }
  async function getData() {
    const { error, questions, reviews } = await API.get(
      'tutor/easyke_mentors/notifications'
    )
    if (!error) {
      setNotification({ questions, reviews })
    }
  }
  useEffect(() => {
    getData()
    document.addEventListener('click', (e) => {
      setBell(false)
    })
  }, [])
  return (
    <div className="js-fixed c-height">
      <header className="c-navbar c-fixed__inner js-fixed__inner js-navbar is-active">
        <div className="c-navbar__inner">
          <div className="l-container l-container-fluid">
            <div className="l-row u-justify-content-between">
              <div className="l-col c-navbar__bar js-navbar-bar">
                <div className="l-row u-align-items-center">
                  <div className="l-col-auto u-d-none-md">
                    <a
                      className="c-navbar__menu__toggle js-menu-toggle js-dropdown__toggle"
                      onClick={(e) => handleToggle(e)}
                    >
                      <Icon name="menu" size="square-sm" cl="c-icon--dark-dk" />
                    </a>
                  </div>
                  <div className="l-col-auto c-navbar__logo ">
                    <a className="c-navbar__logo__link" href="/tutor/qa">
                      <img
                        alt=""
                        className="c-icon"
                        src="/images/logo/logo.svg"
                      />
                    </a>
                  </div>

                  {menu.map((option, i) => {
                    if (option.children) {
                      return (
                        <div
                          id="menuItem"
                          className="c-navbar__menu js-menu-item u-d-block-md c-navbar__menu--stacked"
                          key={option.label + i}
                        >
                          <Dropdown className="c-dropdown js-dropdown">
                            <a
                              className="c-navbar__dd__toggle js-dropdown__toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {option.label}
                              <Icon name="chevron-down" size="square-3xs" />
                            </a>
                            <div className="c-dropdown__popup c-navbar__dd__list js-dropdown__popup">
                              <div className="c-dropdown__popup__inner c-dropdown__list">
                                {option.children.map((optionChildren) => (
                                  <div key={optionChildren.label}>
                                    <a
                                      className="c-dropdown__link-logout"
                                      href={optionChildren.href}
                                    >
                                      {optionChildren.label}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                      )
                    }
                    return (
                      <div
                        id="menuItem"
                        className="c-navbar__menu js-menu-item u-d-block-md c-navbar__menu--stacked"
                        key={option.label + i}
                      >
                        <a href={option.href}>
                          <div className="c-navbar__link">{option.label}</div>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="l-col-auto c-navbar__toolbar u-align-items-md-center u-d-flex">
                <a
                  className="c-navbar__dd__toggle js-dropdown__toggle mr1 relative"
                  onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    setBell(!bell)
                    readAll()
                  }}
                >
                  {count > 0 && (
                    <div className="count c-message-counter c-message-counter--sticked">
                      {count}
                    </div>
                  )}
                  <Icon name="bell" size="square-s" cl="c-icon--dark-dk" />
                </a>
                <div
                  onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
                  aria-labelledby="messages_notifications_d"
                  className={`c-dropdown__popup c-dropdown__popup--right c-navbar__dd__list ${
                    bell ? 'is-active' : ''
                  }`}
                  role="menu"
                >
                  <div className="c-dropdown__popup__inner">
                    <div className="c-navbar__popup">
                      <div className="c-navbar__popup__tabs">
                        <div className="l-row">
                          <div className="c-navbar__popup__tab l-col">
                            <a
                              className={`c-navbar__popup__tab__link ${
                                !tab ? 'is-active' : ''
                              }`}
                              onClick={() => setTab(0)}
                            >
                              <Icon
                                name="message-square"
                                size="square-xs"
                                inline
                                cl="c-icon--dark-ltr"
                              />
                              Questions
                            </a>
                          </div>
                          <div className="c-navbar__popup__tab l-col">
                            <a
                              className={`c-navbar__popup__tab__link ${
                                tab ? 'is-active' : ''
                              }`}
                              onClick={() => setTab(1)}
                            >
                              <Icon
                                name="clipboard"
                                size="square-xs"
                                inline
                                cl="c-icon--dark-ltr"
                              />
                              Reviews
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-content u-h-100"
                        style={{ overflow: 'auto' }}
                      >
                        <div className="c-navbar__popup__content is-active">
                          {notification[tab ? 'reviews' : 'questions']
                            .length ? (
                            notification[tab ? 'reviews' : 'questions'].map(
                              (n, i) => (
                                <a
                                  href={
                                    tab === 0 &&
                                    tutor.id !== n.easyke_mentor_id &&
                                    n.status !== 'opened'
                                      ? '/tutor/qa'
                                      : n.target_link
                                  }
                                  key={i}
                                  className="badge_notification unread c-dropdown__link c-dropdown__link--bm u-p-s"
                                >
                                  <h6 className="u-mb-0 u-font-size-xs">
                                    {n.title} {n.target_user}
                                  </h6>
                                  <p className="u-my-xxs">{n.content}</p>
                                  <div className="u-color-dark-lt u-font-size-3xs">
                                    {n.time_ago}
                                  </div>
                                </a>
                              )
                            )
                          ) : (
                            <div className="system_notification pa15 gray tc db">
                              <p>
                                {tab
                                  ? 'Reviews and ratings from students and moderators will be shown here.'
                                  : 'Student questions and comments will be shown here.'}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="c-navbar__popup__footer"
                        role="presentation"
                      >
                        <a
                          className="see_all_msg_notif"
                          href="/tutor/notifications"
                        >
                          See all
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="c-navbar__dd__toggle js-dropdown__toggle"
                  href="/tutor/settings"
                >
                  <Icon name="user" size="square-s" cl="c-icon--dark-dk" />
                </a>
              </div>
            </div>
          </div>
          <Menu
            mode="inline"
            className={`${
              menuMobile ? 'u-d-block' : 'u-d-none'
            } u-d-none-md mt15`}
            style={{ width: '100%' }}
          >
            {menu.map((option, i) => {
              if (option.children) {
                return (
                  <SubMenu key={option.label + i} title={option.label}>
                    {option.children.map((optionChildren, index) => (
                      <Menu.Item key={optionChildren.label + index}>
                        <a className="f6" href={optionChildren.href}>
                          {optionChildren.label}
                        </a>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                )
              }
              return (
                <Menu.Item id="menuItem" key={option.label + i}>
                  <a href={option.href}>
                    <div className="f6">
                      {option.label}
                      &nbsp;
                    </div>
                  </a>
                </Menu.Item>
              )
            })}
          </Menu>
        </div>
      </header>
    </div>
  )
}
