/* eslint-disable  no-script-url */
/* eslint-disable */
import { Icon } from '../../common'

const FOOTER_CONTAINER = [
  {
    name: '荃程无忧',
    list: [
      {label: '关于我们', link: '/about'},
      // {label: '荃程无忧团队', link: '/team'},
      {label: '加入我们', link: '/careers'},
      {label: '联系我们', link: '/contact'},
      {label: '服务条款', link: '/terms'},
      {label: '隐私条款', link: '/privacy'},
    ],
  },

  {
    name: '联系我们',
    list: [
      {label: '邮箱 info@quanchengvip.com'},
      {label: '微信企业号 quanchengvip'},
    ],
  },
]

export default function Footer() {
  return (
    <footer className="c-footer">
      <div className="l-container">
        <div className="l-row u-justify-content-center">
          {
            FOOTER_CONTAINER.map((option) => (
              <section className="l-col-12 l-col-md" key={option.name}>
                <ul className="c-footer__nav">
                  <li className="c-footer__nav__item">
                    <h6 className="c-footer__title">{option.name || ''}</h6>
                    <ul className="c-footer__list">
                      {
                        option.list.map((item) => (
                          <li className="c-footer__list__item" key={item.label}>
                            {item.link ? <a className="c-footer__link" href={item.link}>{item.label}</a> : item.label}
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                </ul>
              </section>
            ))
          }
          <div className="l-col-12 l-col-md">
            <ul className="c-footer__list u-text-right-md">
              <li className="c-footer__list__item">
                &copy; 深圳荃程资讯科技有限公司&nbsp;
                {`${new Date().getFullYear()}`}
              </li>
              <li className="c-footer__list__item">
                <a className="c-footer__link u-d-inline-block" href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">
                  粤ICP备2022118869号
                </a>
              </li>
              <li className="c-footer__list__item">
                <div className="l-row u-justify-content-center u-justify-content-md-end">
                  <div className="l-col-auto">
                    <table width="135" border="0" cellPadding="2" cellSpacing="0"
                           title="Click to Verify - This site chose DigiCert SSL for secure e-commerce and confidential communications.">
                      <tbody>
                        <tr>
                          <td width="135" align="right" valign="top">
                            <br />
                            <a
                              href="https://www.digicert.com/what-is-ssl-tls-https/"
                              target="_blank"
                              style={{color: '#6b6b6b', textDecoration: 'none', font: '7px verdana,sans-serif', letterSpacing: '.5px', textAlign: 'right', margin: 0, padding: 0}}
                            >
                              ABOUT
                              SSL CERTIFICATES
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
