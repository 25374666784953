/*
 * @Description:
 * @Author: shilin deng
 * @Date: 2021-02-22 17:51:40
 */
/* eslint-disable */
import * as Sentry from '@sentry/node'
import { Provider } from 'react-redux'
import { Provider as ProviderAlert } from 'react-alert'
import ConfigProvider from 'antd/lib/config-provider'
import zhCN from 'antd/lib/locale/zh_CN'
import { useStore } from '../store'
import '../styles/global.scss'
import bem from '../utils/bem'
import API from '../utils/api'
import redirect from '../utils/redirect'
// import Layout from '../components/layouts/website'
import Layout from '../components/layouts/layout.js'
import { TUTOR_LOGOUT_PAGE } from '../utils/constant'

const geoip = require('fast-geoip');
import Cookies from '../utils/cookies'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENV,
    // enabled: process.env.NODE_ENV === 'production',
    enabled: false,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const option = {
  timeout: 2000,
  containerStyle: {
    zIndex: 100001,
  },
}

const AlertTemplate = ({ options, message }) => {
  let color
  switch (options.type) {
    case 'info':
      color = '#328ADA'
      break
    case 'success':
      color = '#00AF54'
      break
    case 'error':
      color = '#EF6578'
      break
    default:
      color = 'gray'
  }
  return (
    <div className="react-alert" style={{ background: color }}>
      {message}
    </div>
  )
}

function EasykeApp({ Component, pageProps }) {
  const store = useStore()
  return (
    <Provider store={store}>
      <ProviderAlert template={AlertTemplate} {...option}>
        <ConfigProvider locale={zhCN}>
          <Layout user={pageProps.user} tutor={pageProps.tutor} path={pageProps.path}>
            <Component {...pageProps} />
          </Layout>
        </ConfigProvider>
      </ProviderAlert>
    </Provider>
  )
}
EasykeApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}
  const flag = typeof window === 'undefined' ? ctx : null

  try {
    let ip;
    const { req } = ctx;

    if (req?.headers["x-forwarded-for"]) {
      ip = req.headers["x-forwarded-for"].split(',')[0]
    } else if (req?.headers["x-real-ip"]) {
      ip = req.connection.remoteAddress
    } else {
      ip = req?.connection.remoteAddress
    }
    console.log("ipaddress -> ", ip);
    const geo = await geoip.lookup(ip);
    Cookies.set('is_china', geo?.country === 'CN', flag)
  } catch (error) {
    console.log(error);
  }

  if (ctx.pathname.startsWith('/tutor')) {
    pageProps = { path: true }
    if (Cookies.get('auth_token_tutor', flag)) {
      const { error, tutor } = await API.getTutorInfo(flag)
      if (!error) {
        pageProps = { tutor, path: true }
        if (TUTOR_LOGOUT_PAGE.includes(ctx.pathname)) {
          redirect('/tutor/qa', flag)
          return { pageProps }
        }
        if (bem.redirectTutorByUrl(tutor, ctx)) {
          redirect(bem.redirectTutorByUrl(tutor, ctx), flag)
          return { pageProps }
        }
      } else {
        Cookies.set('auth_token_tutor', '', flag)
        if (ctx.pathname !== '/tutor') {
          redirect('/tutor/login', flag)
        }
      }
    } else {
      if (!TUTOR_LOGOUT_PAGE.includes(ctx.pathname)) {
        redirect('/tutor/login', flag)
        return { pageProps }
      }
    }
  } else if (Cookies.get('auth_token', flag)) {
    const { error, user } = await API.getUserInfo(flag)
    if (!error) {
      pageProps = { user }
      if (bem.redirectByUrl(user, flag)) {
        redirect(bem.redirectByUrl(user, flag), flag)
        return { pageProps }
      }
    } else {
      Cookies.set('auth_token', '', flag)
      if (ctx.pathname.startsWith('/user')) {
        redirect('/', flag)
        return { pageProps }
      }
    }
  } else if (ctx.pathname.startsWith('/user')) {
    redirect('/', flag)
    return { pageProps }
  }
  if (!Component.getServerSideProps) {
    return { pageProps }
  }
  pageProps = await Component.getServerSideProps({ ctx })
  return { pageProps }
}

export default EasykeApp
