/**
 *@Description 登录按钮
 *@author meng wang
 *@date 2020/7/30
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showLoginModal, hideLoginModal } from '../../../store/actions/user'
import UserLoginModal from './user-login-modal'

export default function UserLoginLink({className}) {
  const loginModalStatus = useSelector(({ login }) => login.loginModal)
  const dispatch = useDispatch()
  return (
    <div className={className}>
      <div onClick={() => dispatch(showLoginModal())}>登录</div>
      <UserLoginModal
        hidden={loginModalStatus}
        close={() => dispatch(hideLoginModal())}
      />
    </div>
  )
}
