/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable  */

import React, { useState } from 'react'
import Router from 'next/router'
import { useAlert } from 'react-alert'
import { Modal, Btn } from '../../common'
import { USER_COUNTRY } from '../../../utils/constant'
import API from '../../../utils/api'

export default function UserSignupModal({ close }) {
  const alert = useAlert()
  const [isActive, setActive] = useState('')
  const [loading, setLoading] = useState(false)

  async function submit() {
    if (!isActive) {
      alert.error('请选择留学国家')
      return
    }
    setLoading(true)
    const params = {
      country: isActive,
    }

    const res = await API.post('user/demo_user_country', params)
    setLoading(false)
    if (res.success) {
      // Router.push('/user/dashboard')
      window.location.reload()
    } else {
      alert.error(res.message)
    }
  }

  return (
    <Modal width="true" close={close} hideClose className="p-login-modal">
      <div className="fw5 f4 u-color-dark-dk mb2">请选择留学国家</div>
      <div className="c-modal__divider">
        <div className="l-row no-gutters u-align-items-center">
          <div className="l-col">
            <hr className="u-my-m" />
          </div>
          <div className="l-col">
            <div className="l-col c-modal__divider__text">留学国家</div>
          </div>
          <div className="l-col">
            <hr className="u-my-m" />
          </div>
        </div>
      </div>
      <div className="l-row c-country-select u-mb-m">
        {USER_COUNTRY.map((option, i) => {
          const [CN, ENG, ID] = option
          return (
            <label
              className={`l-col-3 c-country-select__item js-users__country-field ${
                isActive === ID ? 'is-active' : ''
              }`}
              key={i}
              onClick={() => setActive(ID)}
            >
              <div
                className={`c-country-select__icon c-country-select__icon--${ENG}`}
              />
              {CN}
            </label>
          )
        })}
      </div>
      <div className="o-form-group">
        <Btn
          tag="a"
          disabled={loading}
          content={loading ? '请稍候...' : '确定'}
          size="lg"
          type="primary"
          fullWidth
          cl="js-login__user-submit btn pv05 f4"
          onClick={submit}
        />
      </div>
    </Modal>
  )
}
