/* eslint-disable camelcase */
/**
 *@Description username_login
 *@author meng wang
 *@date 2020/7/23
 */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Router from 'next/router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { hideLoginModal, showResetModal } from '../../../store/actions/user'
import { Input, Btn } from '../../common'
import API from '../../../utils/api'
import Cookies from '../../../utils/cookies'

export default function UserLoginByUsername({ type, close }) {
  const dispatch = useDispatch()
  const [errorMsg, setErrorMsg] = useState('')
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('* 此处不可空白'),
  })

  async function submit(values, { setSubmitting }) {
    if (type === 'student') {
      setSubmitting(true)
      const { auth_token, error } = await API.post('user/auth', values)
      setSubmitting(false)
      if (error) {
        setErrorMsg(typeof error === 'string' ? error : '登录失败')
      } else {
        Cookies.set('auth_token', auth_token)
        // const path = window.location.pathname
        Router.push('/user/dashboard')
        // if (path === '/') {
        //   Router.push('/user/dashboard')
        // } else {
        //   window.location.reload()
        // }
        dispatch(hideLoginModal())
      }
    } else {
      const { error } = await API.post('parent/auth', values)
      if (error) {
        setErrorMsg(typeof error === 'string' ? error : '登录失败')
      } else {
        Router.push('/parents/dashboard')
      }
    }
  }
  // 忘记密码
  function linkClick() {
    close()
    dispatch(showResetModal())
  }

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          id="username-login-form"
          className="js-user__login-username js-login-item"
        >
          <div className="u-text-left mb05 f5">用户名</div>
          <Input
            error={errors.username}
            positionType=""
            name="username"
            type="text"
            placeholder="请输入用户名"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            inputClass="o-form-group"
          />
          <div className="flex u-justify-content-between mb05 ">
            <div className="u-text-left f5">密码</div>
            <a
              className="u-text-right u-color-primary-dk f6"
              onClick={() => linkClick()}
            >
              忘记密码？
            </a>
          </div>
          <Input
            name="password"
            type="password"
            placeholder="请输入密码"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            inputClass="o-form-group"
          />
          {errorMsg && (
            <div className="o-form-group">
              <div className="c-alert c-alert--alt js-login__username-error">
                {errorMsg}
              </div>
            </div>
          )}
          <div className="o-form-group mt15">
            <div id="next_step">
              <Btn
                tag="a"
                content={isSubmitting ? '请稍候...' : '登录'}
                type="primary"
                fullWidth
                cl="js-login__user-submit btn pv05 f4"
                size="lg"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
