// /* eslint-disable no-plusplus */
import React, { useState } from 'react'
import Menu from 'antd/lib/menu'
import Icon from '../../common/icon'
import Dropdown from '../../common/dropdown'
import {
  UserLoginLink,
  UserSignupLink,
  UserResetPassword,
} from '../../pages/user'
import { HEADER_LINK } from '../../../utils/constant'

const { SubMenu } = Menu
export default function Header() {
  const [menuMobile, setMenu] = useState(false)
  function handleToggle(e) {
    e.preventDefault()
    setMenu(!menuMobile)
  }

  return (
    <div className="js-fixed c-height">
      <header className="c-navbar c-fixed__inner js-fixed__inner js-navbar is-active">
        <div className="c-navbar__inner">
          <div className="l-container l-container-fluid">
            <div className="l-row u-justify-content-between">
              <div className="l-col c-navbar__bar js-navbar-bar">
                <div className="l-row u-align-items-center">
                  <div className="l-col-auto u-d-none-md">
                    <a
                      className="c-navbar__menu__toggle js-menu-toggle js-dropdown__toggle"
                      onClick={(e) => handleToggle(e)}
                      // onMouseLeave={(e) => handleToggle(e)}
                    >
                      <Icon name="menu" size="square-sm" cl="c-icon--dark-dk" />
                    </a>
                  </div>
                  <div className="l-col-auto c-navbar__logo ">
                    <a className="c-navbar__logo__link" href="/">
                      <img
                        alt=""
                        className="c-icon"
                        src="/images/logo/logo.svg"
                      />
                    </a>
                  </div>

                  {HEADER_LINK.map((option, i) => {
                    if (option.children) {
                      return (
                        <div
                          id="menuItem"
                          className="c-navbar__menu js-menu-item u-d-block-md c-navbar__menu--stacked"
                          key={option.label + i}
                        >
                          <Dropdown className="c-dropdown js-dropdown">
                            <a
                              className="c-navbar__dd__toggle js-dropdown__toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {option.label}
                              <Icon name="chevron-down" size="square-3xs" />
                            </a>
                            <div className="c-dropdown__popup c-navbar__dd__list js-dropdown__popup">
                              <div className="c-dropdown__popup__inner c-dropdown__list">
                                {option.children.map((optionChildren) => (
                                  <div key={optionChildren.label}>
                                    <a
                                      className="c-dropdown__link-logout"
                                      href={optionChildren.href}
                                    >
                                      {optionChildren.label}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                      )
                    }
                    return (
                      <div
                        id="menuItem"
                        className="c-navbar__menu js-menu-item u-d-block-md c-navbar__menu--stacked"
                        key={option.label + i}
                      >
                        <a href={option.href}>
                          <div className="c-navbar__link">
                            {option.label}
                            &nbsp;
                            {option.isNew && (
                              <div className="c-navbar__tag c-tag c-tag--sm">
                                NEW
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="l-col-auto c-navbar__toolbar u-align-items-md-center u-d-flex">
                <UserLoginLink className="c-navbar__toolbar__item js-login_popup c-navbar__link" />
                <UserSignupLink className="c-navbar__toolbar__item js-signup_popup c-navbar__link" />
                <UserResetPassword />
              </div>
            </div>
          </div>
          <Menu
            mode="inline"
            className={`${
              menuMobile ? 'u-d-block' : 'u-d-none'
            } u-d-none-md mt15`}
            style={{ width: '100%' }}
          >
            {HEADER_LINK.map((option, i) => {
              if (option.children) {
                return (
                  <SubMenu key={option.label + i} title={option.label}>
                    {option.children.map((optionChildren, index) => (
                      <Menu.Item key={optionChildren.label + index}>
                        <a className="f6" href={optionChildren.href}>
                          {optionChildren.label}
                        </a>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                )
              }
              return (
                <Menu.Item id="menuItem" key={option.label + i}>
                  <a href={option.href}>
                    <div className="f6">
                      {option.label}
                      &nbsp;
                    </div>
                  </a>
                </Menu.Item>
              )
            })}
          </Menu>
        </div>
      </header>
    </div>
  )
}
