/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable  */

/**
 *@Description signup
 *@author meng wang
 *@date 2020/7/29
 */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'
import * as Yup from 'yup'
import Router from 'next/router'
import { Modal, SelectTextGroup, Btn, TextBtnGroup, Input } from '../../common'
import { USER_PHONE_CODE_SELECT, USER_COUNTRY } from '../../../utils/constant'
import { showLoginModal } from '../../../store/actions/user'
import API from '../../../utils/api'
import Cookies from '../../../utils/cookies'

export default function UserSignupModal({ hidden, close }) {
  const dispatch = useDispatch()
  const alert = useAlert()
  const [isActive, setActive] = useState('')
  const [flag, setFlag] = useState(true)
  const [countdown, setCountdown] = useState(60)
  const [resendFlag, setResendFlag] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [sessionId, setSessionId] = useState('')

  async function getRucaptcha() {
    const { error, path, session_id} = await API.get(`rucaptcha?t=${Date.now()}`)
    if (error) {
      setFlag(true)
      return
    }
    setImgUrl(`${path}?t=${Date.now()}`)
    setSessionId(session_id || '')
  }
  useEffect(() => {
    if(!hidden) {
      getRucaptcha()
    }
  }, [hidden])
  function countdownControl() {
    let time = 59
    setCountdown(time)
    const set = setInterval(() => {
      setCountdown(time)
      time -= 1
      if (time === -1) {
        clearInterval(set)
      }
    }, 1000)
  }

  function resendCode(fn) {
    setFlag(true)
    getRucaptcha()
    // setResendFlag(true)
    // fn()
  }
  // 切换登录
  function exLogin() {
    close()
    dispatch(showLoginModal())
  }

  async function submit(values) {
    let recaptcha = document.getElementById("recaptcha")
    setErrorMsg('')
    if (!isActive) {
      alert.error('请选择留学国家')
      return
    }
    const phone = values.country.split(' ')[1].concat(values.phone)
    if (flag || resendFlag) {
      const params = {
        phone,
        rucaptcha: recaptcha.value,
        session_id: sessionId,
      }
      const { error } = await API.post('user/register/captch', params)
      setResendFlag(false)
      if (error) {
        setFlag(true)
        setErrorMsg(error)
        getRucaptcha()
        return
      }
      countdownControl()
      setFlag(false)
    } else {
      const params = {
        phone,
        captch: values.code,
        country_id: isActive,
      }
      const { error, auth_token } = await API.post(
        'user/register/signup',
        params
      )
      if (error) {
        setFlag(true)
        getRucaptcha()
        setErrorMsg(error)
        return
      }
      Cookies.set('auth_token', auth_token)
      const path = window.location.pathname
      if (path === '/wenda') {
        Router.push('/life-demo')
      } else if (path === '/xuanke') {
        Router.push('/courses')
      } else {
        Router.push(path === '/' ? '/user/dashboard' : path)
      }
    }
  }

  return (
    <Modal
      width="true"
      hidden={hidden}
      close={close}
      className="p-login-modal"
    >
      {/* <h1 className="c-modal__title">快速注册</h1> */}
      <div className="fw5 f4 u-color-dark-dk mb2">快速注册</div>
      <div className="c-modal__divider">
        <div className="l-row no-gutters u-align-items-center">
          <div className="l-col">
            <hr className="u-my-m" />
          </div>
          <div className="l-col">
            <div className="l-col c-modal__divider__text">选择留学国家</div>
          </div>
          <div className="l-col">
            <hr className="u-my-m" />
          </div>
        </div>
      </div>
      <div className="l-row c-country-select u-mb-m">
        {USER_COUNTRY.map((option, i) => {
          const [CN, ENG, ID] = option
          return (
            <label
              className={`l-col-3 c-country-select__item js-users__country-field ${
                isActive === ID ? 'is-active' : ''
              }`}
              key={i}
              onClick={() => setActive(ID)}
            >
              <div
                className={`c-country-select__icon c-country-select__icon--${ENG}`}
              />
              {CN}
            </label>
          )
        })}
      </div>
      <Formik
        initialValues={{ country: '中国 +86', phone: '', code: '' }}
        validationSchema={Yup.object().shape({
          phone: Yup.string().required('* 请输入手机号码'),
        })}
        onSubmit={submit}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="js-users__signup-form">
            <div className="o-form-group">
              <SelectTextGroup
                options={USER_PHONE_CODE_SELECT}
                selectProps={{
                  name: 'country',
                  value: values.country,
                  setFieldValue,
                  search: true,
                }}
                inputProps={{
                  name: 'phone',
                  placeholder: '手机号',
                  type: 'text',
                  value: values.phone,
                  error: errors.phone,
                  onChange: handleChange,
                }}
                onBlur={handleBlur}
                inputClass="validate[required] js-users__phone"
              />
            </div>
            {!flag && (
              <div className="o-form-group js-phone-login__code">
                <div className="c-form-control">
                  <TextBtnGroup
                    inputProps={{
                      name: 'code',
                      placeholder: '手机收到的6位数验证码',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.code,
                    }}
                    inputClass="code_field o-form-group"
                    btn_class="js-phone-login__countdown c-btn--alt"
                    btn_disabled={Boolean(countdown || isSubmitting)}
                    btn={
                      countdown
                        ? `${countdown}秒后可以重发`
                        : isSubmitting
                        ? '请稍候...'
                        : '重发'
                    }
                    onClick={() => resendCode(handleSubmit)}
                  />
                </div>
              </div>
            )}
            {
              flag && (
                <>
                  <Input placeholder="请输入图形验证码" id="recaptcha" />
                  <div className="flex pb05">
                    <div>
                      <img src={imgUrl} alt="recaptcha" />
                    </div>
                    <p className="pl05 pt1 f6 p-login-rucaptcha" onClick={() => getRucaptcha()}>看不清? 换一张</p>
                  </div>
                </>
              )
            }
            {errorMsg && (
              <div className="o-form-group">
                <div className="js-phone-login__code__error msg c-alert c-alert--alt">
                  {errorMsg}
                </div>
              </div>
            )}
            <div className="o-form-group">
              <Btn
                tag="a"
                disabled={isSubmitting && !resendFlag}
                content={
                  isSubmitting && !resendFlag
                    ? '请稍候...'
                    : flag
                    ? '发送验证码'
                    : '注册'
                }
                size="lg"
                type="primary"
                fullWidth
                cl="js-login__user-submit btn pv05 f4"
                onClick={handleSubmit}
              />
            </div>
            <div className="o-form-group u-font-size-xxs u-my-m">
              注册即代表你同意
              <a href="/terms" target="_blank">
                《荃程无忧服务条款》
              </a>
              和
              <a href="/privacy" target="_blank">
                《荃程无忧隐私条款》
              </a>
            </div>
          </form>
        )}
      </Formik>
      <div className="mt5 p-login p-login-foot">
        <div className="line" />
        <span className="f5 mh1">
          已有账号？
          <a onClick={exLogin}>登录</a>
        </span>
        <div className="line" />
      </div>
    </Modal>
  )
}
