/**
 *@Description 登录按钮
 *@author meng wang
 *@date 2020/7/30
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSignupModal, hideSignupModal } from '../../../store/actions/user'
import UserSignupModal from './user-signup-modal'

export default function UserSignupLink({className}) {
  const signupModalStatus = useSelector(({ signup }) => signup.signupModal)
  const dispatch = useDispatch()

  return (
    <div className={className}>
      <div onClick={() => dispatch(showSignupModal())}>注册</div>
      <UserSignupModal
        hidden={signupModalStatus}
        close={() => dispatch(hideSignupModal())}
      />
    </div>
  )
}
