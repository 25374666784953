/**
 *@Description upgrade banner
 *@author meng wang
 *@date 2021/1/25
 */
import { useState, useEffect } from 'react'

export default function Upgrade() {
  const [hide, setHide] = useState(false)
  const [hide2, setHide2] = useState(false)
  const dontShowBannerPath = ['/user/step1', '/user/payment']
  useEffect(() => {
    const path = window.location.pathname
    if (dontShowBannerPath.includes(path)) {
      setHide(true)
    }
    function handleScroll() {
      const win = document.documentElement
      if (win.scrollTop + win.clientHeight === win.scrollHeight) {
        setHide2(true)
      } else {
        setHide2(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      {
        !hide && !hide2 ? (
          <a className="demo-bar text-center" href="/user/step1">
            <span className="f6 f3-m fw5 normal-ns">您正在使用体验版本</span>
            <div className="btn btn-clear medium_text double_margin_left">升级会员</div>
          </a>
        ) : null
      }
    </>
  )
}
