/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/**
 *@Description phone_login
 *@author meng wang
 *@date 2020/7/23
 */
import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Router from 'next/router'
import { Btn, SelectTextGroup, TextBtnGroup } from '../../common'
import { USER_PHONE_CODE_SELECT } from '../../../utils/constant'
import API from '../../../utils/api'
import Cookies from '../../../utils/cookies'

export default function UserLoginByPhone() {
  const [flag, setFlag] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [countdown, setCountdown] = useState(60)
  const [resendFlag, setResendFlag] = useState(false)

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required('* 此处不可空白'),
  })

  function countdownControl() {
    let time = 59
    setCountdown(time)
    const set = setInterval(() => {
      setCountdown(time)
      time -= 1
      if (time === -1) {
        clearInterval(set)
      }
    }, 1000)
  }

  function resendCode(fn) {
    setResendFlag(true)
    fn()
  }

  async function submit(values) {
    setErrorMsg('')
    const phone = values.country.split(' ')[1].concat(values.phone)
    if (flag || resendFlag) {
      const params = {
        phone,
      }
      const { error } = await API.post('user/auth/captch', params)
      setResendFlag(false)
      if (error) {
        setErrorMsg(error.toString())
        return
      }
      countdownControl()
      setFlag(false)
    } else {
      const params = {
        phone,
        captch: values.code,
      }
      const { auth_token, error } = await API.post('user/auth/verify', params)
      if (error) {
        setErrorMsg(error.toString())
      } else {
        Cookies.set('auth_token', auth_token)
        Router.push('/user/dashboard')
        // const path = window.location.pathname
        // if (path === '/') {
        //   Router.push('/user/dashboard')
        // } else {
        //   window.location.reload()
        // }
      }
    }
  }

  return (
    <Formik
      initialValues={{ country: '中国 +86', phone: '', code: '' }}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form className="js-user__login-phone js-login-item">
          <div className="o-form-group js-phone-login">
            <SelectTextGroup
              options={USER_PHONE_CODE_SELECT}
              selectProps={{
                name: 'country',
                setFieldValue,
                value: values.country,
                search: true,
              }}
              inputProps={{
                name: 'phone',
                placeholder: '手机号',
                type: 'text',
                value: values.phone,
                error: errors.phone,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              inputClass="validate[required] js-users__phone"
            />
          </div>
          {!flag && (
            <div className="o-form-group js-phone-login__code">
              <div className="c-form-control">
                <TextBtnGroup
                  inputProps={{
                    name: 'code',
                    placeholder: '手机收到的6位数验证码',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.code,
                  }}
                  inputClass="code_field o-form-group"
                  btn_class="js-phone-login__countdown c-btn--alt"
                  btn_disabled={Boolean(countdown || isSubmitting)}
                  btn={
                    countdown
                      ? `${countdown}秒后可以重发`
                      : isSubmitting
                        ? '请稍候...'
                        : '重发'
                  }
                  onClick={() => resendCode(handleSubmit)}
                />
              </div>
            </div>
          )}
          {errorMsg && (
            <div className="o-form-group">
              <div className="js-phone-login__code__error msg c-alert c-alert--alt">
                {errorMsg}
              </div>
            </div>
          )}
          <div className="o-form-group">
            <div id="next_step">
              <Btn
                tag="a"
                disabled={isSubmitting && !resendFlag}
                content={
                  isSubmitting && !resendFlag
                    ? '请稍候...'
                    : flag
                      ? '发送验证码'
                      : '登录'
                }
                size="lg"
                type="primary"
                fullWidth
                cl="js-login__user-submit btn pv05 f4"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
