/**
 *@Description service link
 *@author meng wang
 *@date 2020/8/12
 */

import Link from 'next/link'

export default function Tabs({option, onClick}) {
  const {url = '#', name = '', description = '', image = '', rails = false} = option
  if (rails) {
    return (
      <a href={url} onClick={onClick} className="c-dropdown__link c-dropdown__link--lt c-service">
        <img src={`/images/navbar/ft-${image}.svg`} alt={image} />
        <div className="c-service__title">{name}</div>
        <div className="c-service__text">{description}</div>
      </a>
    )
  }
  return (
    <Link href={url}>
      <a onClick={onClick} className="c-dropdown__link c-dropdown__link--lt c-service">
        <img src={`/images/navbar/ft-${image}.svg`} alt={image} />
        <div className="c-service__title">{name}</div>
        <div className="c-service__text">{description}</div>
      </a>
    </Link>
  )
}
