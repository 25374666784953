/*
 * @Description: 登录荃程无忧
 * @Author: shilin deng
 * @Date: 2020-12-30 16:50:45
 */
/*eslint-disable*/
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSignupModal, showResetModal } from '../../../store/actions/user'
import UserLoginByUsername from './user-login-by-username'
import UserLoginByPhone from './user-login-by-phone'
import UserLoginByWechat from './user-login-by-wechat'
import { Modal, Tabs } from '../../common'

const LINKLIST = ['用户名登录', '手机验证登录', '微信二维码登录']

export default function UserLoginModal({ hidden, close }) {
  const dispatch = useDispatch()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentLink, setCurrentLink] = useState(0)

  // 登录方式切换
  function linkClick(link, index) {
     if (index === 3) {
      close()
      dispatch(showSignupModal())
    } else {
      setCurrentLink(index)
    }
  }


  return (
    <Modal
      width="true"
      hidden={hidden}
      close={close}
      className="p-login-modal"
    >
      <div className="fw5 f4 u-color-dark-dk mb2">欢迎登录荃程无忧</div>

      <div className="tab-content">
        <div className="js-login-tab-content tab-pane active">
          {!currentIndex ? (
            (() => {
              switch (currentLink) {
                case 0:
                  return <UserLoginByUsername type="student" close={close} />
                case 1:
                  return <UserLoginByPhone />
                case 2:
                  return <UserLoginByWechat />
                default:
                  return null
              }
            })()
          ) : (
            <UserLoginByUsername type="parent" />
          )}
          <div className="flex u-justify-content-between mt1">
            {currentIndex === 0 &&
              LINKLIST.map((link, index) =>
                index !== currentLink ? (
                  <div
                    className=""
                    key={link}
                    onClick={() => linkClick(link, index)}
                  >
                    <div className="f6 a p-login-login">{link}</div>
                  </div>
                ) : null
              )}
          </div>
        </div>
      </div>

      <div className="mt5 p-login p-login-foot">
        <div className="line" />
        <span className="f5 mh1">
          没有账号？立即
          <a onClick={(e) => linkClick(e, 3)}>注册</a>
        </span>
        <div className="line" />
      </div>
    </Modal>
  )
}
