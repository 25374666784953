/* eslint-disable */
// /* eslint-disable no-plusplus */
/**
 *@Description user header
 *@author meng wang
 *@date 2020/8/11
 */
import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import Router from 'next/router'
import { Icon } from '../../common'
import ServiceLink from './service-link'
import UpgradePopup from './upgrade-banner'
import SelectCountry from './select-country'
import Cookies from '../../../utils/cookies'
import { DemoTimetable } from '../../pages/qa'
import sessions from '../../../utils/sessions'
import API from '../../../utils/api'

export default function Header({ user, userLink }) {
  const alert = useAlert()
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [path, setpath] = useState(true)
  const [title, setTitle] = useState('')
  const [timetableStatus, setTimetableStatus] = useState(false)

  useEffect(() => {
    if (Router.pathname === '/user/dashboard') {
      setpath(false)
    }
    setTimeout(() => {
      const meta = document.getElementsByTagName('meta')
      setTitle(meta.location?.content || '荃程无忧')
    }, 500)
  }, [])

  function back() {
    window.history.back()
  }
  async function logout() {
    const { error } = await API.post('user/logout')
    if (error) {
      alert.error(error)
      return
    }
    Cookies.set('auth_token', '')
    sessions.set('user', null)
    sessions.set('impersonating', null)
    window.location.href = '/'
  }

  // show message
  function showMessage(e, message) {
    alert.error(message)
    e.preventDefault()
  }

  // service link click
  function handleClick(e, option) {
    if (option.key === 'dashboard' || option.children?.length) {
      return
    }
    if (option.url === '/live_tutorials/user/dashboard' && !user?.time_zone) {
      e.preventDefault()
      Router.push('/user/setting')
    }
    if (option.key === 'timetable') {
      if (user.actual_demo) {
        if (user.service_package?.demo) {
          setTimetableStatus(true)
        } else if (user.status !== 'active' && user.status !== 'demo') {
          showMessage(e, '我们正在创建您的个人档案，感谢您的耐心等待')
        } else if (user.status === 'deferred') {
          showMessage(e, '当前服务暂时还未开通')
        }
      }
    } else if (option.no_click) {
      showMessage(e, '我们正在创建您的个人档案，感谢您的耐心等待')
    } else if (!option.ready || option.is_deferred) {
      if (option.ready || option.url !== '/courses_pending') {
        showMessage(e, '当前服务暂时还未开通')
      }
    }
  }

  return (
    <div className="js-fixed" style={{ height: '65px' }}>
      <header className="c-navbar c-fixed__inner js-fixed__inner js-navbar is-active">
        <div className="c-navbar__inner">
          <div className="l-container l-container-fluid">
            <div className="l-row u-justify-content-between">
              <div className="c-navbar__mobile l-col-4 u-d-none-md">
                {path && (
                  <a className="c-navbar__mobile__item" onClick={back}>
                    <Icon
                      name="arrow-left"
                      size="square-sm"
                      cl="c-icon--dark-dk"
                    />
                  </a>
                )}
              </div>
              <div className="c-navbar__mobile l-col-4 u-d-none-md">
                <div className="c-navbar__mobile__item c-navbar__mobile__title">
                  {title}
                </div>
              </div>
              <div
                className="l-col c-navbar__bar js-navbar-bar"
                style={{ position: 'static' }}
              >
                <div className="l-row u-align-items-center">
                  <div className="l-col-auto c-navbar__logo u-d-block-md u-d-none">
                    <a href="/" className="c-navbar__logo__link">
                      <img
                        alt=""
                        className="c-icon"
                        src="/images/logo/logo.svg"
                      />
                    </a>
                  </div>
                  {userLink?.map((option, index) => (
                    <div
                      className="c-navbar__menu u-d-block-md u-d-none"
                      key={option.name}
                    >
                      <a
                        href={option.url}
                        onClick={(e) => handleClick(e, option)}
                        className="c-navbar__link"
                        onMouseEnter={() => setHoverIndex(index)}
                      >
                        {option.name}
                        {option.children && (
                          <Icon name="chevron-down" size="square-3xs" />
                        )}
                      </a>
                      {option.children && (
                        <div
                          onMouseLeave={() => setHoverIndex(-1)}
                          className={`c-dropdown__popup c-dropdown__popup--wide c-navbar__dd__list js-dropdown__popup ${
                            hoverIndex === index && 'is-active'
                          }`}
                        >
                          <div className="c-dropdown__popup__inner c-dropdown__popup__inner--wide c-dropdown__menu">
                            <div className="l-container">
                              <div className="l-row no-gutters">
                                {option.children.map((serviceOptions) => (
                                  <div
                                    className="l-col"
                                    key={serviceOptions.name}
                                  >
                                    <ServiceLink
                                      onClick={(e) =>
                                        handleClick(e, serviceOptions)
                                      }
                                      option={serviceOptions}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="c-navbar__toolbar__item u-d-block-md u-d-none">
                <div className="c-dropdown js-dropdown">
                  <a
                    className="c-navbar__dd__toggle js-dropdown__toggle"
                    data-toggle="dropdown"
                    aria-expanded={false}
                    onMouseEnter={() => setHoverIndex(userLink.length)}
                  >
                    <Icon name="user" size="square-s" cl="c-icon--dark-dk" />
                  </a>
                  <div
                    onMouseLeave={() => setHoverIndex(-1)}
                    className={`c-dropdown__popup c-dropdown__popup--right c-navbar__dd__list js-dropdown__popup ${
                      hoverIndex === userLink?.length && 'is-active'
                    }`}
                  >
                    <div className="c-dropdown__popup__inner c-dropdown__list">
                      {!user?.experience && (
                        <div>
                          <a
                            href="/user/setting"
                            className="c-dropdown__link-logout"
                          >
                            设置
                          </a>
                        </div>
                      )}
                      {user?.service_package?.gallup && user.gallup_test && (
                        <div>
                          <a
                            href="/user/result"
                            className="c-dropdown__link-logout"
                          >
                            个人优势
                          </a>
                        </div>
                      )}
                      {user?.north_america &&
                        !user?.service_package?.high_school &&
                        user?.status === 'active' && (
                          <div>
                            <a href="/faq" className="c-dropdown__link-logout">
                              常见问题
                            </a>
                          </div>
                        )}
                      <div>
                        <a onClick={logout} className="c-dropdown__link-logout">
                          退出
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {timetableStatus && (
        <DemoTimetable close={() => setTimetableStatus(false)} />
      )}
      {user && user.status === 'demo' && user.country_id && <UpgradePopup />}
      {user && user.status === 'demo' && !user.country_id && <SelectCountry />}
    </div>
  )
}
