/* eslint-disable */

/**
 *@Description react dropdown
 *@author meng wang
 *@date 2020/8/11
 */
import React, {useState} from 'react'

export default function Tooltip() {
  const [state, setState] = useState('')
  return (
    <span className='js-dropdown'>
      <a className='js-dropdown__toggle p-homework__info__tooltip u-color-dark-dk'
         onMouseOver={()=>setState('is-active')}
         onMouseOut={()=>setState('')}>Office Hour</a>
      <div className={state + ' c-tooltip__popup c-tooltip__popup--tl js-dropdown__popup c-tooltip--animated'}>
        <div className='c-tooltip__popup__inner c-tooltip__popup__inner--light'>
          <div className='c-form-control__tooltip'>
            <strong> Class Meeting:
            Mondays. Wednesday and Fridays 11:00-11:15 am for ENGL 102 G2,
            and 12:00-12:50 pm for ENGL 102 G3</strong>
            <br/>
            <strong>
             Classroom:
              HC 2-34 for ENGL 102 G2; HC 2-12 for ENGL 102 G3
            </strong>
            <hr/>
            <div className='l-row'>
              <div className='l-col-md-6'>
                <ul className='u-mb-0-md'>
                  <li>
                    <strong>Instructor: </strong>
                    Dr. Leilei Chen
                  </li>
                  <li>
                    <strong>Office: </strong>
                    HC 4-89
                  </li>
                  <li>
                    <strong>Office Hours: </strong>
                    Wednesdays 3-4 pm or by appointment
                  </li>
                  <li>
                    <strong>Email: </strong>
                    Ichen@ualberta.ca
                  </li>
                </ul>
              </div>
              <div className='l-col-md-6'>
                <ul className='u-mb-0-md'>
                  <li>
                    <strong>Instructor Assistant: </strong>
                    Naily Elhalabi
                  </li>
                  <li>
                    <strong>Office: </strong>
                    HC 3-44
                  </li>
                  <li>
                    <strong>Office Hours: </strong>
                    Posted on eClass
                  </li>
                  <li>
                    <strong>Email: </strong>
                    elhablabi@ualberta.ca
                  </li>
                </ul>
              </div>
            </div>
        </div>
    </div>
  </div>
</span>
  )
}
