/*
 * @Description: 导师登录layout
 * @Author: shilin deng
 * @Date: 2021-06-17 16:35:56
 */
/*eslint-disable*/

export default function Layout({ children }) {
  return (
    <div className="p-mtl u-p-l-md">
      <div className="o-block o-block--white u-py-xl p-bat__apply__inner">
        <div className="l-container u-px-m-md u-px-0 u-text-center">
          <img
            alt=""
            className="u-mb-m"
            src="/images/logo/logo.svg"
          />
          {children}
          <img alt="" className="u-mt-s" src="/images/pages/tutor-applicants/login.svg" />
        </div>
      </div>
      <section className="o-section u-pt-l">
        <div className="p-bat__footer u-text-center">
          &copy; Notesolution Inc. - &copy; Quancheng {`${new Date().getFullYear()}`}
        </div>
      </section>
    </div>
  )
}
