/**
 *@Description reset
 *@author meng wang
 *@date 2020/7/28
 */
import React from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { Modal, SelectTextGroup, Input, Btn } from '../../common'
import { USER_PHONE_CODE_SELECT } from '../../../utils/constant'
import { hideResetModal } from '../../../store/actions/user'
import API from '../../../utils/api'

export default function UserResetPassword() {
  const alert = useAlert()
  const resetModalStatus = useSelector(({ reset }) => reset.resetModal)
  const dispatch = useDispatch()

  async function submit(values) {
    const params = {}
    let field
    if (values.phone) {
      field = '手机'
      params.phone = values.country.split(' ')[1].concat(values.phone)
    } else if (values.email) {
      field = '邮箱'
      params.email = values.email
    } else {
      alert.error('请输入手机号码或者电子邮箱')
      return
    }
    const { error } = await API.post('user/auth/reset', params)
    if (error) {
      alert.error(error)
    } else {
      alert.info(`重置密码链接将会发送至你注册的${field}`)
      dispatch(hideResetModal())
    }
  }

  return (
    <Modal
      className="p-login-modal"
      width="true"
      hidden={resetModalStatus}
      close={() => dispatch(hideResetModal())}
    >
      <h1 className="c-modal__title">重置密码</h1>
      <p>请输入手机号或邮箱，我们将会给您发送验证码</p>
      <br />
      <Formik
        initialValues={{ country: '中国 +86', phone: '', email: '' }}
        onSubmit={submit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <form className="js-reset-form">
            <div className="forgot_password js-reset-password">
              <SelectTextGroup
                options={USER_PHONE_CODE_SELECT}
                selectProps={{
                  name: 'country',
                  setFieldValue,
                  value: values.country,
                  search: true,
                }}
                inputProps={{
                  name: 'phone',
                  placeholder: '手机号',
                  value: values.phone,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
              />
            </div>
            <div className="c-modal__divider">
              <div className="l-row no-gutters u-align-items-center">
                <div className="l-col">
                  <hr className="u-my-m" />
                </div>
                <div className="l-col">
                  <div className="l-col c-modal__divider__text">或者</div>
                </div>
                <div className="l-col">
                  <hr className="u-my-m" />
                </div>
              </div>
            </div>
            <div className="o-form-group">
              <Input
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="邮箱地址"
                autoComplete="off"
                inputClass="validate[required] js-users__email"
              />
            </div>
            <div className="o-form-group u-mt-m">
              <Btn
                content="下一步"
                size="lg"
                type="primary"
                fullWidth
                cl="js-login__reset-password btn pv05 f4"
                onClick={handleSubmit}
              />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
