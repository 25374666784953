/* eslint-disable */

/**
 *@Description wechat_login
 *@author meng wang
 *@date 2020/7/27
 */
import React from 'react'

export default function UserLoginByWechat() {
  const resp = {
    country_code: 'auth',
    // redirect_url: 'https://feature.quanchengvip.com/wechats/wechat_login',
    redirect_url: 'https://www.quanchengvip.com/wechats/wechat_login',
    wechat_app_id: 'wx8e373fd1b903b756',
  }
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
  const wxElement = document.body.appendChild(s)
  wxElement.onload = function () {
    var obj = new WxLogin({
      id: 'wechat_container',
      appid: resp.wechat_app_id,
      scope: 'snsapi_login',
      redirect_uri: resp.redirect_url,
      state: 'quancheng_wechat_login_' + resp.country_code,
      style: 'black',
      href: 'https://www.quanchengvip.com//assets/wechat-login.css'
    })
  }
  return (
    <div id='wechat_container' className='js-login-item'/>
  )
}
