/*
 * @Description: bottom banner
 * @Author: shilin deng
 * @Date: 2021-02-23 14:00:16
 */
import React, { useState } from 'react'
import { Btn } from '../../common'
import ControlModal from './controlModal'

export default function BottomBanner({ hideContact = false, user }) {
  const [ifTry, setIfTry] = useState(false)
  function wantTry() {
    setIfTry(true)
  }
  return (
    <section className=" o-section__gr">
      <div className="o-section__bt__bg o-section__pd u-text-center">
        <div className="l-container">
          <div className="l-row u-justify-content-center">
            <div className="l-col-10 ph0">
              <div className="o-section__header o-section__header--xl u-mb-s u-mt-m o-section__bottom__title">
                因为懂得，所以专业
              </div>
              <p className="u-font-size-base o-section__bottom__f_title">
                未来的路上，荃程无忧与你同行
              </p>
              <div className="l-row u-justify-content-center">
                {!hideContact && (
                  <div className="l-col-md-4 l-col-12 u-mb-xs">
                    <Btn
                      tag="a"
                      href="#"
                      target=""
                      content="免费咨询"
                      size="lg"
                      fullWidth
                      cl="btn btn-white"
                      onClick={wantTry}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {ifTry && <ControlModal user={user} close={() => setIfTry(false)} />}
      </div>
    </section>
  )
}
