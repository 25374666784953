/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description contact
 *@author meng wang
 *@date 2020/11/16
 */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Router from 'next/router'
import { useAlert } from 'react-alert'
import ContactModal from './contactModal'
import DemoUpgradeModal from './demoUpgradeModal'
import ServiceRequestModal from './seviceRequestModal'
import { showSignupModal } from '../../../store/actions/user'
import API from '../../../utils/api'

export default function ControlModal({ close, flag, user }) {
  const alert = useAlert()
  const dispatch = useDispatch()
  const [type, setType] = useState(0)

  async function sendRequest() {
    const meta = document.getElementsByTagName('meta')
    const params = {
      service_type: meta.location.content,
      path: Router.pathname === '/' ? 'homepage' : Router.pathname,
    }
    const { error } = await API.post('user/service_request', params)
    if (error) {
      alert.error(error)
      return
    }
    if (user && user.actual_demo) {
      setType(1)
    } else if (user) {
      setType(2)
    } else if (flag) {
      close()
      dispatch(showSignupModal())
    } else {
      setType(3)
    }
  }
  useEffect(() => {
    if (user) {
      sendRequest()
    } else if (flag) {
      close()
      dispatch(showSignupModal())
    } else {
      setType(3)
    }
  }, [])
  return (
    <>
      <DemoUpgradeModal hidden={type === 1 ? false : 'hidden'} close={close} />
      <ServiceRequestModal
        hidden={type === 2 ? false : 'hidden'}
        close={close}
      />
      <ContactModal hidden={type === 3 ? false : 'hidden'} close={close} />
    </>
  )
}
