/* eslint-disable jsx-a11y/label-has-associated-control */
/**
/**
 *@Description demo user upgrade
 *@author meng wang
 *@date 2020/11/16
 */
import React from 'react'
import { Btn, Modal } from '../../common'

export default function DemoUpgradeModal({hidden, close}) {
  return (
    <Modal width="100%" close={close} hidden={hidden}>
      <h1 className="c-modal__title">您的申请已提交</h1>
      <div className="l-row u-justify-content-center">
        <div className="l-col-8">
          <p className="o-section__paragraph">
            请等待我们的学业规划师联系您，或拨打
            400-696-9197。如果您想直接升级会员，
            请点击直接升级进入下一步。
          </p>
          <Btn tag="a" content="等待联系" type="alt" onClick={close} />
          &nbsp;
          <Btn href="/payment" tag="a" content="直接升级" cl="js-demo_user_upgrade_service" />
        </div>
      </div>
    </Modal>
  )
}
