import {combineReducers} from 'redux'
import * as types from '../types/user'

// login reducer
const loginReducer = (state = {loginModal: 'hidden'}, {type}) => {
  switch (type) {
    case types.LOGIN_MODAL_SHOW:
      return {
        loginModal: false,
      }
    case types.LOGIN_MODAL_HIDDEN:
      return {
        loginModal: 'hidden',
      }
    default:
      return state
  }
}

// signup reducer
const signupReducer = (state = {signupModal: 'hidden'}, {type}) => {
  switch (type) {
    case types.SIGNUP_MODAL_SHOW:
      return {
        signupModal: false,
      }
    case types.SIGNUP_MODAL_HIDDEN:
      return {
        signupModal: 'hidden',
      }
    default:
      return state
  }
}

// reset reducer
const resetReducer = (state = {resetModal: 'hidden'}, {type}) => {
  switch (type) {
    case types.RESET_MODAL_SHOW:
      return {
        resetModal: false,
      }
    case types.RESET_MODAL_HIDDEN:
      return {
        resetModal: 'hidden',
      }
    default:
      return state
  }
}

// user reducer
const userReducer = (state = {userMsg: null}, {type, content}) => {
  switch (type) {
    case types.SAVE_USER_MSG:
      return {
        userMsg: content || null,
      }
    default:
      return state
  }
}

// COMBINED REDUCERS
const reducers = {
  login: loginReducer,
  signup: signupReducer,
  reset: resetReducer,
  userMsg: userReducer,
}

export default combineReducers(reducers)
