/* eslint-disable jsx-a11y/label-has-associated-control */
/**
/**
 *@Description service request
 *@author meng wang
 *@date 2020/11/16
 */
import React from 'react'
import { Btn, Modal } from '../../common'

export default function ServiceRequestModal({hidden, close}) {
  return (
    <Modal width="100%" close={close} hidden={hidden}>
      <h1 className="c-modal__title">您的申请已提交</h1>
      <p className="o-section__paragraph">
        老师将会尽快与您取得联系。
      </p>
      <Btn tag="a" content="好的" onClick={close} />
    </Modal>
  )
}
