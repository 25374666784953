/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/**
/**
 *@Description contact
 *@author meng wang
 *@date 2020/11/16
 */
import React from 'react'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'
import Router from 'next/router'
import * as Yup from 'yup'
import { Btn, Input, Modal } from '../../common'
import { USER_COUNTRY } from '../../../utils/constant'
import API from '../../../utils/api'

export default function ContactModal({ hidden, close }) {
  const alert = useAlert()
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('* 此处不可空白'),
    phone_number: Yup.string().required('* 此处不可空白'),
    wechat: Yup.string().required('* 此处不可空白'),
    school: Yup.string().required('* 此处不可空白'),
    major: Yup.string().required('* 此处不可空白'),
  })

  async function submit(values) {
    const {
      country_id,
      full_name,
      phone_number,
      wechat,
      school,
      major,
    } = values
    if (!country_id) {
      alert.error('请选择留学国家')
      return
    }
    const params = {
      country_id,
      full_name,
      phone_number,
      wechat,
      school,
      major,
      source: Router.pathname === '/' ? 'homepage' : Router.pathname,
    }
    const { error } = await API.post('pages/register_contact', params)
    if (error) {
      alert.error('该手机号码已经被使用')
      return
    }
    close()
    alert.info('感谢您的信任，我们会在24小时内与您联系。')
  }

  return (
    <Modal width="true" close={close} hidden={hidden}>
      <h1 className="c-modal__title">免费咨询</h1>
      <Formik
        initialValues={{
          country_id: '',
          full_name: '',
          phone_number: '',
          wechat: '',
          school: '',
          major: '',
        }}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="js-homepage__contact-form">
            <div className="c-modal__divider">
              <div className="l-row no-gutters u-align-items-center">
                <div className="l-col">
                  <hr className="u-my-m" />
                </div>
                <div className="l-col c-modal__divider__text">选择留学国家</div>
                <div className="l-col">
                  <hr className="u-my-m" />
                </div>
              </div>
            </div>
            <div className="l-row c-country-select u-mb-s">
              {USER_COUNTRY.map((option, i) => {
                const [CN, ENG, ID] = option
                return (
                  <label
                    className={`l-col-3 c-country-select__item js-users__country-field ${
                      values.country_id === ID ? 'is-active' : ''
                    }`}
                    key={i}
                    onClick={() => setFieldValue('country_id', ID)}
                  >
                    <div
                      className={`c-country-select__icon c-country-select__icon--${ENG}`}
                    />
                    {CN}
                  </label>
                )
              })}
            </div>
            <Input
              error={touched.full_name && errors.full_name}
              name="full_name"
              placeholder="姓名"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.full_name}
              inputClass="o-form-group"
            />
            <Input
              error={touched.phone_number && errors.phone_number}
              name="phone_number"
              placeholder="手机号"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              inputClass="o-form-group"
            />
            <Input
              error={touched.wechat && errors.wechat}
              name="wechat"
              placeholder="微信号"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.wechat}
              inputClass="o-form-group"
            />
            <Input
              error={touched.school && errors.school}
              name="school"
              placeholder="意向学校"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.school}
              inputClass="o-form-group"
            />
            <Input
              error={touched.major && errors.major}
              name="major"
              placeholder="专业"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.major}
              inputClass="o-form-group"
            />
            <Btn
              tag="a"
              content={isSubmitting ? '请稍候...' : '提交'}
              size="lg"
              onClick={handleSubmit}
            />
          </form>
        )}
      </Formik>
    </Modal>
  )
}
